import React from "react"
import Layout from "../Components/layout"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "../Components/ssp/hero"
import ContentTable from "../Components/ssp/contentTable"
import InquireSection from "../Components/inquirelink"
import SEO from "../Components/SEO"

export default function Products() {

  const data = useStaticQuery(graphql`
    {
      directory: allContentJson {
        nodes {
          products {
            list {
              items {
                name
                image{
                  src {
                    childImageSharp {
                      fixed(width: 80){
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
              title
            }
          }
        }
      }
      banner: file(relativePath: { eq: "products/main/product_banner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  console.log(data.directory.nodes[0].products.list)

  return (
    <Layout>
      <SEO title="Products" description="Fastlink offers a wide variety of products that will cater all the consumer and enterprise's needs, wants, and requirements"></SEO>
      <Hero
        image={data.banner.childImageSharp.fluid}
        heading="Our Products"
        description="Fastlink offers a wide variety of products that will cater all the consumer and enterprise's needs, wants, and requirements. Each product is carefull designed and integrated to deliver satisfying results. Coupled with great support and service, we can gaurantee cost-efficient products to serve your needs."
      />
      <ContentTable data={data.directory.nodes[0].products.list} />
      <InquireSection></InquireSection>
    </Layout>
  )
}
