import React, { useState, useEffect } from "react"
import { BsChevronDown } from "react-icons/bs"
import BackgroundImage from 'gatsby-background-image'


import style from "./styles.module.css"

const Hero = props => {

    const [anchorTarget, setAnchorTarget] = useState(null)

    useEffect(() => {
        setAnchorTarget(document.getElementById("table"))
    }, [])

    const handleClick = event => {
        event.preventDefault()
        anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" })
    }

    return (
        <BackgroundImage
            Tag="section"
            className={style.hero}
            fluid={props.image}
            backgroundColor={`#040e18`}
        >
            <div>
                <h1>{props.heading} </h1>
                <p>
                    {props.description}

                    <a
                        href="#table"
                        onClick={handleClick}
                        ariaLabel={`Scroll to #table`}
                    >
                        <BsChevronDown className={style.downIcon}></BsChevronDown>
                    </a>

                </p>

            </div>
        </BackgroundImage>
    )
}

export default Hero
