import React, { useState } from "react"
import { Container } from "reactstrap"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ContentTable = ({ data }) => {
    //   let index = 0
    const [itemIndex, setIndex] = useState(0)
    //   console.log(data.items)

    const productCategories = data.map((category, index) => {
        return (
            <h4 key={index}
                className={`product-category ${itemIndex === index ? "active" : ""} `}
                onClick={() => {
                    setIndex(index)
                }}
            >
                {category.title}
            </h4>
        )
    })
    const products = data[itemIndex].items.map((product, index) => {
        var slug = data[itemIndex].title.toLowerCase().replace(/ /g, "-") + "/" + product.name.toLowerCase().replace(/ /g, "-")
        return (
            <div className="product" key={index}>
                <div className="product-image">
                    <Img fixed={product.image.src.childImageSharp.fixed} />
                </div>
                <div>
                    <div className="product-content">
                        <p>{data[itemIndex].title}</p>
                        <h4>{product.name}</h4>
                    </div>
                    <Link to={slug} className="product-link"><a>Learn More</a></Link>
                </div>
            </div>
        )
    })
    //   console.log(props.data)

    return (
        <>
            <Container
                fluid
                sm="12"
                className="pt-5 pb-5"
                style={{ backgroundColor: `#F3F3F3` }}
                id="table"
            >
                <h2 className="text-center pb-5 heading1"><span>Products We Offer</span></h2>
                <div className="table-container">
                    <div className="mb-3 category-container">
                        {productCategories}
                    </div>
                    <div className="products">
                        {products}
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ContentTable
